import React from 'react'
import advantageImg1 from 'images/team-landing/advantages/advantage-img-1.png'
import advantageImg2 from 'images/team-landing/advantages/advantage-img-2.png'
import advantageImg3 from 'images/team-landing/advantages/advantage-img-3.png'
import { useTranslation, Trans } from 'react-i18next';

const AdvantagesSection = () => {

    const { t } = useTranslation('landings')

    return (
        <div className='tl-advantages-section' data-aos="fade-up">
            <h1>{t('advantagesSectionText1')}</h1>
            <div className="tl-advantages-container">
                <div className="tl-advantage-block">
                    <img src={advantageImg1} alt="" />
                    <h3>{t('advantagesSectionText2')}</h3>
                    <p>{t('advantagesSectionText3')}</p>
                </div>
                <div className="tl-advantage-block">
                    <img src={advantageImg2} alt="" />
                    <h3>{t('advantagesSectionText4')}</h3>
                    <p>{t('advantagesSectionText5')}</p>
                </div>
                <div className="tl-advantage-block">
                    <img src={advantageImg3} alt="" />
                    <h3>{t('advantagesSectionText6')}</h3>
                    <p>{t('advantagesSectionText7')}</p>
                </div>
            </div>
        </div>
    )
}

export default AdvantagesSection