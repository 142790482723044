import React, { useEffect } from "react";
import ReactDOM from "react-dom";
import {
  BrowserRouter, Routes, Route, useLocation
} from "react-router-dom";
import { Toaster } from 'react-hot-toast'
import moment from 'moment';
import UserContext from "../hooks/UserContext";
import { QueryClient, QueryClientProvider } from "react-query";
import TeamLanding from "../components/landings/routes/Team";
import IALanding from "../components/landings/routes/IA";
import Aos from "aos"
import Nom035 from "../components/landings/routes/Nom035";
import ErrorBoundary from "../components/utils/ErrorBoundary";
import '../i18next/i18n';

/** 
 * Allows to dinamically load a React Router component (with routes).
 * 
 * Each route component with nested routes, should be loaded dinamically to avoid having big chunks
 * of JS code loaded at once in the browser. The Loadable component allows to load those routes.
 */
const Loadable = (Component) => props => (
  <React.Suspense fallback={<div></div>}>
    <Component {...props} />
  </React.Suspense>
);

// Routes to be loaded dinamically:
const TherapeuticTreatments = Loadable(React.lazy(() => import("../components/therapeuticTreatments/Routing")));
const TherapeuticExercises = Loadable(React.lazy(() => import("../components/therapeuticExercises/Routing")));
const DemoRoutes = Loadable(React.lazy(() => import("../components/demos/Routing")));
const BlogGallery = Loadable(React.lazy(() => import("../components/blogGallery/Routing")));
const Journey = Loadable(React.lazy(() => import("../components/journey/Routing")));
const Companies = Loadable(React.lazy(() => import("../components/companies/Routing")));
const HomeLanding = Loadable(React.lazy(() => import("../components/landings/routes/Home")));
const CompaniesLanding = Loadable(React.lazy(() => import("../components/landings/routes/B2b")));
const Subscriptions = Loadable(React.lazy(() => import("../components/subscriptions/Routing")));
const SpecialitiesLanding = Loadable(React.lazy(() => import("../components/landings/routes/SpecialityHome")));
const CampaignLanding = Loadable(React.lazy(() => import("../components/landings/routes/CampaignHome")));
const CompanyLanding = Loadable(React.lazy(() => import("../components/landings/routes/CompanyHome")));
const WelcomePageUsersIndex = Loadable(React.lazy(() => import("../components/usersHome/routes/Index")));
const IndexMindwell = Loadable(React.lazy(() => import("../components/usersHome/routes/IndexMindwell")));
const UserChat = Loadable(React.lazy(() => import("../components/userChat/routes/Index")));
const ProfessionalProfile = Loadable(React.lazy(() => import("../components/professionalProfile/routes/Index")));
const OpenUserActions = Loadable(React.lazy(() => import("../components/openUserActions/Routing")));
const LandingsActions = Loadable(React.lazy(() => import("../components/landings/Routing")));
const UserProfile = Loadable(React.lazy(() => import("../components/profile/Routing")));
const TreatmentLanding = Loadable(React.lazy(() => import("../components/landings/routes/TreatmentLanding")));
const OnboardingQuestionnaire = Loadable(React.lazy(() => import("../components/landings/routes/OnboardingQuestionnaire")));
const OnboardingRecommendationIndex = Loadable(React.lazy(() => import("../components/onboarding/recommendation/routes/Index")));
const WellnessEventsShow = Loadable(React.lazy(() => import("../components/wellnessEvents/routes/Show")));
const EbookLanding = Loadable(React.lazy(() => import("../components/landings/routes/Ebook")));
const WellnessEvents = Loadable(React.lazy(() => import("../components/usersHome/components/WellnessEventsPage")));

const setMobileMenuElement = () => {
  let location = useLocation();
  const mainRoutes = {
    "/app/welcome": "welcome",
    "/app/chat_room": "chat_room",
    "/app/journey": "journey",
    "/users/reservar": "calendar",
    "/users/proximas-consultas": "consultations",
    "/users/panel_redireccion": "config",
    "/app/subscriptions/15/new": "gold"
  };

  useEffect(() => {
    const menuFinder = `/${window.location.pathname.split("/").slice(2).join("/")}`;

    const selectedRoute = document.querySelector(`.toolbar-link-${mainRoutes[menuFinder]}`);
    const toolbarElements = document.querySelectorAll(".toolbar-link");
    const backButton = document.querySelector(`.toolbar-link-back`);

    if (toolbarElements.length > 0) {
      if (selectedRoute) {
        // remove active class from all menu element and show class from back button
        backButton.classList.remove("d-block");
        // add active class to current active menu element and hide class from back button
        backButton.classList.add("d-none");
        // now, we set the active menu element
        if (toolbarElements.length > 0 && selectedRoute) {
          // remove active class from all menu element
          toolbarElements.forEach((el) => el.classList.remove("active"));
          // add active class to current active menu element
          selectedRoute.classList.add("active");
        }
      } else {
        var activeMenuButton = document.querySelector(".toolbar-link.active");
        if (activeMenuButton) {
          document.querySelector(".toolbar-link-back a").href = activeMenuButton.href;
        }

        // remove hide class from back button and show class from img
        backButton.classList.remove("d-none");
        // add show class from back button and hide class from img
        backButton.classList.add("d-block");
      }
    }

    let navbar = document.getElementById("mainNav")
    if (navbar != null) {
      if ((window.location.pathname.includes('/app/treatments/') && window.location.pathname.includes('/objectives')) && !navbar.classList.contains("navbar-treatments"))
        navbar.classList.add("navbar-treatments")
      else if ((!window.location.pathname.includes('/app/treatments') && !window.location.pathname.includes('/objectives')) && navbar.classList.contains("navbar-treatments")) {
        navbar.classList.remove("navbar-treatments")
      }

      if (window.location.pathname.includes('/app/onboarding')) {
        navbar.classList.add("navbar-treatments");
      }
    } else {
      let navbar = document.getElementsByClassName("toolbar-container")[0]
      if (navbar != null) {
        if ((window.location.pathname.includes('/app/treatments') || window.location.pathname.includes('/app/onboarding')) && !navbar.classList.contains("navbar-treatments"))
          navbar.classList.add("navbar-treatments")
        else if (!window.location.pathname.includes('/app/treatments') && navbar.classList.contains("navbar-treatments")) {
          navbar.classList.remove("navbar-treatments")
        }
      }
    }

  }, [location]);
}

const queryClient = new QueryClient();

/**
 * Main app component.
 */
const App = ({ images, firstTime }) => {
  const currentDateTime = moment()
  setMobileMenuElement();

  useEffect(() => {
    //initialize the fade effect in landing, needed to apply data-aos="fade-up" in each div that you want to have the effect

    var container = document.getElementById("therapeutic-exercises__container");
    if (!container) {
      Aos.init({ duration: 1000 })
    }
  }, [])

  return (
    <Routes>
      <Route path={"/:site"}>
        <Route element={<HomeLanding />} index={true} />
        <Route path={`terapia/*`} element={<SpecialitiesLanding />} />

        <Route path={"tratamientos/:planSlug"} element={<TreatmentLanding />} />
        <Route path={"treatments/:planSlug"} element={<TreatmentLanding />} />

        <Route path={"onboarding/:planSlug"} element={<OnboardingQuestionnaire />} />
        <Route path={"planes/:planSlug"} element={<TreatmentLanding />} />
        <Route path={`juntasporlasaludmental`} element={<CampaignLanding />} />
        <Route path={"empresas"} element={<CompaniesLanding />} />
        <Route path={"empresa/*"} element={<CompanyLanding />} />
        <Route path={"empresas/programa-de-apoyo"} element={<TeamLanding />} />
        <Route path={"empresas/inteligencia-artificial"} element={<IALanding />} />
        <Route path={"empresas/nom-035"} element={<Nom035 />} />
        <Route path={"empresas/ebook/:campaign_identifier"} element={<EbookLanding />} />

        {/* 
        /u/* is for actions of users that are open, like answering a test without being signed in
      */}
        <Route path={"u/*"} element={<OpenUserActions />} />
        {/* 
        /l/* is for custom landings like Joy, Vitta, etc.
      */}
        <Route path={"l/*"} element={<LandingsActions />} />

        <Route path={"demo/*"} element={<DemoRoutes />} />

        <Route path="app">
          <Route path={`profile/*`} element={<UserProfile />} />
          <Route path={`subscriptions/*`} element={<Subscriptions />} />
          <Route path={`treatments/*`} element={<TherapeuticTreatments />} />
          <Route path={`exercises/*`} element={<TherapeuticExercises />} />
          <Route path={`blog_gallery/*`} element={<BlogGallery />} />
          <Route path={`journey/*`} element={<Journey />} />
          <Route path={`chat_room/*`} element={<UserChat />} />
          <Route path={`eventos/:eventId`} element={<WellnessEventsShow />} />
          <Route path={`wellness_events`} element={<WellnessEvents />} />
          <Route path={`welcome`} element={<WelcomePageUsersIndex
            images={images}
            backTerapiPass={window.fondo_terapipass}
            firstTime={firstTime}
          />} />
          <Route path={`welcome/mindwell`} element={<IndexMindwell />} />
          <Route path="companies/*" element={<Companies />} />
          <Route path="professional_profile" element={<ProfessionalProfile />} />
          <Route path="onboarding/:botType" element={<OnboardingRecommendationIndex />} />
        </Route>
      </Route>
    </Routes>
  );
};


document.addEventListener("DOMContentLoaded", () => {
  const site = document.body.dataset.site;
  const userId = window.terapi_uid;

  let container = document.getElementById("therapeutic-exercises__container");

  if (!container) {
    container = document.getElementById("home_landing_container");
  }

  const firstTime = (window.firstTime) ? firstTime : null;
  const images = [
    { url: (window.foto_camleiva) ? foto_camleiva : null },
    { url: (window.foto_camcruz) ? foto_camcruz : null },
    { url: (window.foto_rodquiroga) ? foto_rodquiroga : null },
  ];
  let fotoCaratula = (window.fotoCaratula) ? fotoCaratula : null

  ReactDOM.render(
    <BrowserRouter>
      <QueryClientProvider client={queryClient}>
        <UserContext.Provider value={{ id: userId, site: site }}>
          <ErrorBoundary>
            <App
              cover={fotoCaratula}
              images={images}
              firstTime={firstTime}
            />
          </ErrorBoundary>
        </UserContext.Provider>
        <Toaster containerStyle={{ zIndex: 99999999 }} />
      </QueryClientProvider>
    </BrowserRouter>,
    container);
});
