import React from 'react'
import AboutUsSection from './AboutUsSection'
import LogoSection from './LogoSection'
import OnlineTherapySection from './OnlineTherapySection'
import ReserveAnHourSection from './ReserveAnHourSection'

import DesktopTerapiLogo from 'images/landing-v2/desktop_new_logo.svg';
import DesktopMindwellLogo from 'images/mindwell-logo/mindwell-logo-v2.svg';
import { useTranslation } from 'react-i18next';

import desktopLogo from 'images/landing-v2/desktop_new_logo.svg'

const Footer = ({ b2b }) => {

    const {t} = useTranslation('landings')
    const domain = document.body.dataset.domain

    return (
        <div className='footer-container'>
            <div className="footer-sections-container">
                <LogoSection
                    desktopLogo={domain == "mindwell" ? DesktopMindwellLogo : DesktopTerapiLogo}
                    b2b={b2b}
                    logoClass={domain == "mindwell" ? "mindwellLogo" : "terapiLogo"}
                    contactEmail={domain == "mindwell" ? "hello@mindwell.ai" : "contacto@terapi-app.com"}
                />
                {domain != "mindwell" && <AboutUsSection />}
                {domain != "mindwell" && <OnlineTherapySection />}
                {domain != "mindwell" && <ReserveAnHourSection />}
                {domain == "mindwell" && 
                    <div className="footer-section">
                        <h4>Features</h4>
                        {Array.from({ length: 12 }, (_, i) => (
                            <a href="#">{t('footerFeatureText' + (i+1).toString())}</a>
                        ))}
                    </div>
                }
            </div>

            <p className='company-text'>© 2023 Psycaid SPA</p>
        </div>
    )
}

export default Footer
