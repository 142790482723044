import React from 'react'
import Button from '../../../../designSystem/Buttons/Index'
import { useTranslation, Trans } from 'react-i18next';

const WellnessTeamSection = () => {

  const { t } = useTranslation('landings')

  return (
    <div className='tl-wellness-team-section' data-aos="fade-up">
        <div className="tl-wellness-team-section-left">
            <h1>{t('wellnessTeamSectionText1')}</h1>
            <p>
              <Trans i18nKey={'wellnessTeamSectionText2'}>
                Entregar beneficios de consultas no basta. En Terapi tus empleados tendrán un <b>equipo de bienestar completo</b> para que puedan acceder a ayuda en el momento oportuno. Nuestro equipo construirá los <b>planes de bienestar</b> más acordes para cada una de las necesidades de tus colaboradores, desde programas breves para necesidades puntuales, hasta programas de psicoterapia basados en evidencia.
              </Trans>
            </p>
            <Button
                type='fill'
                color='accent'
                text={t('wellnessTeamSectionText3')}
                onClickHandler={() => window.location.href = `/${document.body.dataset.site}/empresas#contact-section`}
            />
        </div>
        <div className="tl-wellness-team-section-right">

        </div>
    </div>
  )
}

export default WellnessTeamSection