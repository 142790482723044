import React from 'react'
import firstImg from 'images/companies-landing/nom-landing/nom-steps-1.png'
import secondImg from 'images/companies-landing/nom-landing/nom-steps-2.png'
import thirdImg from 'images/companies-landing/nom-landing/nom-steps-3.png'
import fourthImg from 'images/companies-landing/nom-landing/nom-steps-4.png'

const NomStepsSection = () => {
    return (
    <div className='nom-steps-section-container'>
        <h1 className='nom-steps-section-main-title'>Más que nunca las empresas necesitan cuidar el bienestar de sus empleados</h1>
        <div className="nom-steps-section-information-section">
            <div className="nom-steps-section-information-block">
                <img src={firstImg} alt="" />
                <h1 className='nom-steps-section-information-block-title'>
                    Disminuye el tiempo de aplicación del cuestionario
                </h1>
                <p className='nom-steps-section-information-block-description'>
                    Le permitimos a tus colaboradores contestar desde donde más les acomode, desde su móvil o PC, no les tomará más que unos pocos minutos.
                </p>
            </div>
            <div className="nom-steps-section-information-block">
                <img src={secondImg} alt="" />
                <h1 className='nom-steps-section-information-block-title'>
                    Recordatorios automáticos
                </h1>
                <p className='nom-steps-section-information-block-description'>
                    Recordatorios automáticos para los colaboradores que no han contestado. ¡Asegúrate que todos contesten el cuestionario en tiempo record!
                </p>
            </div>
            <div className="nom-steps-section-information-block">
                <img src={thirdImg} alt="" />
                <h1 className='nom-steps-section-information-block-title'>
                Evidencia documental digital automática
                </h1>
                <p className='nom-steps-section-information-block-description'>
                    Generamos toda la evidencia documental necesaria para cumplir con los requerimientos de la NOM-035 de ¡manera automática!, no te preocupes más por tener que generar tus propios documentos.
                </p>
            </div>
            <div className="nom-steps-section-information-block">
                <img src={fourthImg} alt="" />
                <h1 className='nom-steps-section-information-block-title'>
                    Plan de prevención, mitigación y control
                </h1>
                <p className='nom-steps-section-information-block-description'>
                    Construye un plan de prevención de riesgos psicosociales y aplica medidas de mitigación y control con ayuda de nuestro equipo profesional de salud. ¡Realmente mejora la salud mental en tu lugar de trabajo!
                </p>
            </div>
        </div>
    </div>
    )
}

export default NomStepsSection