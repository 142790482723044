import React, { useEffect } from 'react'
import Button from '../Buttons/Index'
import MobileMenu from './MobileMenu'
import DesktopTerapiLogo from 'images/landing-v2/desktop_new_logo.svg';
import DesktopMindwellLogo from 'images/mindwell-logo/mindwell-logo-v2.svg';
import { useTranslation } from 'react-i18next';
import MobileTerapiLogo from 'images/landing-v2/mobile_new_logo.svg';
import MobileMindwellLogo from 'images/mindwell-logo/mindwell-logo-gray-v2.svg';
import getSite from '../../components/utils/GetSite';
const Navbar = ({ desktopLogo, navbarOptions, buttons, navbarType, mobileButtons, mobileLogo }) => {
    const { t } = useTranslation('footer');
    const domain = document.body.dataset.domain;
    const site = getSite();

    useEffect(() => {
        window.onscroll = function () {
            let node = document.getElementById('navbar-container')
            if (window.pageYOffset !== 0) {
                node.classList.add('sticky-navbar')
            }
            else {
                node.classList.remove('sticky-navbar')
            }
        };

        navbarOptions.forEach((link) => {
            if (link.eventData) {
                window.trackLinks(`#${link.id}`, link.eventData.event, { data: { label: link.name, link: link.url, target: "desktop", resource: link.eventData.resource, resource_type: link.eventData.resource_type } });
            }
        });

        buttons.forEach((link) => {
            if (link.eventData) {
                window.trackLinks(`#${link.id}`, link.eventData.event, { data: { label: link.name, link: link.url, target: "desktop", resource: link.eventData.resource, resource_type: link.eventData.resource_type } });
            }
        });
    }, []);

    return (
        <nav className='navbar-container' id='navbar-container'>
            <div className="logo-container">
                {domain == "mindwell" ?
                    <a href="/">
                        <img className="desktop-logo mindwell" src={DesktopMindwellLogo} alt="" />
                        <img className="mobile-logo mindwell" src={MobileMindwellLogo} alt="" />
                    </a>
                    :
                    <a href="/">
                        <img className="desktop-logo terapi" src={DesktopTerapiLogo} alt="" />
                        <img className="mobile-logo terapi" src={MobileTerapiLogo} alt="" />
                    </a>
                }
                <p style={{ fontSize: "10px", marginBottom: 0, marginTop: 0 }}>By Psycaid</p>
            </div>
            <div className="desktop-options-container">
                <ul>
                    {navbarOptions && navbarOptions.map((option, index) => (
                        <li key={index}>
                            <a href={option.url} id={option.id}>{option.name}</a>
                        </li>
                    ))}

                    {buttons && buttons.map((button, index) => (
                        <Button
                            id={button.id}
                            key={index}
                            type={button.type}
                            color={button.color}
                            text={button.text}
                            onClickHandler={button.onClickHandler}
                        />
                    ))}
                </ul>
            </div>
            <MobileMenu
                navbarOptions={navbarOptions}
                mobileButtons={mobileButtons}
            />
        </nav>
    )
}

export default Navbar
