import React from 'react'
import DesktopLogo from 'images/landing-v2/desktop_new_logo.svg';
import MobileLogo from 'images/landing-v2/mobile_new_logo.svg';
import Footer from '../../../designSystem/Footer/Index';
import Navbar from '../../../designSystem/Navbar/Index';
import IAWellnessSection from '../components/ia/IAWellnessSection';
import IAWorkflowSection from '../components/ia/IAWorkflowSection';
import KnowMoreSection from '../components/ia/KnowMoreSection';
import { useTranslation, Trans } from 'react-i18next';

const IALanding = () => {
  let navbarOptions = []
  const { t } = useTranslation('landings')
  
    if (document.body.dataset.site == 'mx'){
        navbarOptions = [
            //{name: 'IA para el Bienestar', url: "#"},
            {name: 'IA para el Bienestar',  url: `/${document.body.dataset.site}/empresas/inteligencia-artificial`},
            {name:'Programa de Apoyo para tus Empleados', url: `/${document.body.dataset.site}/empresas/programa-de-apoyo`},   
            {name:'Nom-035',  url: `/${document.body.dataset.site}/empresas/nom-035`},
            {name:'Planes', url: `/${document.body.dataset.site}/empresas#pricing-section`},
            {name:'Contactar', url: `/${document.body.dataset.site}/empresas#contact-section`}
        ];
    } else {
        navbarOptions = [
            //{name: 'IA para el Bienestar', url: "#"},
            {name:t('iALandingText1'),  url: `/${document.body.dataset.site}/empresas/inteligencia-artificial`},
            {name:t('iALandingText2'), url:  `/${document.body.dataset.site}/empresas/programa-de-apoyo`},   
            {name:t('iALandingText3'), url: `/${document.body.dataset.site}/empresas#pricing-section`},
            {name:t('iALandingText4'), url: `/${document.body.dataset.site}/empresas#contact-section`}
        ];
    }
    const navbarButtons = [
        {type:"fill", color:"accent", text:t('iALandingText5'), onClickHandler: () => document.getElementById('contact-section').scrollIntoView()},
        {type:"outline", color:"primary", text:t('iALandingText6'), onClickHandler: () => window.location.href = `/${document.body.dataset.site}/users/sign_in`}
    ]
    const mobileButtons = [
        {type:"outline", color:"primary", text:t('iALandingText6'), onClickHandler: () => window.location.href = `/${document.body.dataset.site}/users/sign_in`},
        {type:"fill", color:"accent", text:t('iALandingText5'), onClickHandler: () => document.getElementById('contact-section').scrollIntoView()}
    ]
  return (
    <div className='companies-landing-container tl-team-landing-container'>
      <Navbar
        desktopLogo={DesktopLogo}
        mobileLogo={MobileLogo}
        navbarOptions={navbarOptions}
        buttons={navbarButtons}
        mobileButtons={mobileButtons}
        navbarType={'team'}
      />
      <div className="sections-container">
        <IAWellnessSection/>
        <IAWorkflowSection/>
        <KnowMoreSection/>
      </div>
      <Footer/>
    </div>
  )
}

export default IALanding