import React from 'react'
import Navbar from '../../../designSystem/Navbar/Index';
import DesktopLogo from 'images/landing-v2/desktop_new_logo.svg';
import MobileLogo from 'images/landing-v2/mobile_new_logo.svg';
import Footer from '../../../designSystem/Footer/Index';
import NomHelpSection from '../components/nom_035/NomHelpSection';
import NomStepsSection from '../components/nom_035/NomStepsSection';
import NomWorkflowSection from '../components/nom_035/NomWorkflowSection';
import KnowMoreSection from '../components/ia/KnowMoreSection';

const Nom035 = () => {
    let navbarOptions = []
    if (document.body.dataset.site == 'mx'){
        navbarOptions = [
            //{name: 'IA para el Bienestar', url: "#"},
            {name: 'IA para el Bienestar',  url: `/${document.body.dataset.site}/empresas/inteligencia-artificial`},
            {name:'Programa de Apoyo para tus Empleados', url: `/${document.body.dataset.site}/empresas/programa-de-apoyo`},   
            {name:'Nom-035',  url: `/${document.body.dataset.site}/empresas#psych-test-section`},
            {name:'Planes', url: `/${document.body.dataset.site}/empresas#pricing-section`},
            {name:'Contactar', url: `/${document.body.dataset.site}/empresas#contact-section`}
        ];
    } else {
        navbarOptions = [
            //{name: 'IA para el Bienestar', url: "#"},
            {name: 'IA para el Bienestar',  url: `/${document.body.dataset.site}/empresas/inteligencia-artificial`},
            {name: 'Programa de Apoyo para tus Empleados', url:  `/${document.body.dataset.site}/empresas/programa-de-apoyo`},   
            {name:'Planes', url: `/${document.body.dataset.site}/empresas#pricing-section`},
            {name:'Contactar', url: `/${document.body.dataset.site}/empresas#contact-section`}
        ];
    }
    const navbarButtons = [
        {type:"fill", color:"accent", text:"Agenda una demo", onClickHandler: () => document.getElementById('contact-section').scrollIntoView()},
        {type:"outline", color:"primary", text:"Ingresar", onClickHandler: () => window.location.href = `/${document.body.dataset.site}/users/sign_in`}
    ]
    const mobileButtons = [
        {type:"outline", color:"primary", text:"Ingresar", onClickHandler: () => window.location.href = `/${document.body.dataset.site}/users/sign_in`},
        {type:"fill", color:"accent", text:"Agenda una demo", onClickHandler: () => document.getElementById('contact-section').scrollIntoView()}
    ]
    return (
        <div className='companies-landing-container nom-landing-container'>
            <Navbar
                desktopLogo={DesktopLogo}
                mobileLogo={MobileLogo}
                navbarOptions={navbarOptions}
                buttons={navbarButtons}
                mobileButtons={mobileButtons}
                navbarType={'team'}
            />
            <div className="sections-container">
                <NomHelpSection/>
                <NomStepsSection/>
                <NomWorkflowSection/>
                <KnowMoreSection/>
            </div>
            <Footer/>
        </div>
    )
}

export default Nom035