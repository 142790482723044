import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';
import getLocale from '../components/utils/GetLocale';

i18n
  .use(HttpApi)
  .use(initReactI18next)
  .init({
    backend: {
      loadPath: '/locales/{{lng}}/{{ns}}.json',
    },
    lng: getLocale(),
    fallbackLng: 'en',
    //debug: process.env.NODE_ENV === 'development',
    debug: false,
    interpolation: {
      escapeValue: false, // Not needed for React as it escapes by default
    },
    ns:
      [
        'userHome',
        'blogGallery',
        'chat',
        'companies',
        'journey',
        'landings',
        'therapeuticExercises',
        'therapeuticJournals',
        'therapeuticTreatments',
        'userChat',
        'footer',
        'profile'
      ],
    defaultNS: 'userHome',
  });

export default i18n;

