import React from 'react'
import Button from '../../../../designSystem/Buttons/Index'
import { useTranslation, Trans } from 'react-i18next';

const IAWellnessSection = () => {

  const { t } = useTranslation('landings')

  return (
    <div className='il-ia-wellness-section'>
      <div className="il-ia-wellness-section-left">
        <h1>{t('iAWellnessSectionText1')}</h1>
        <p>
          <Trans i18nKey={'iAWellnessSectionText1'}>
            Cuidar la salud mental de tus colaboradores es <b>mucho más que entregar beneficios de consultas de psicología</b>. Con Terapi, podrás construir <b>programas de bienestar institucionales</b>  que realmente cuiden el bienestar mental y emocional de tus colaboradores. Con ayuda de nuestra <b>IA</b>, construye el programa más efectivo para tu empresa.
          </Trans>
        </p>
        <Button
          type='fill'
          color='accent'
          text={t('iAWellnessSectionText3')}
          onClickHandler={() => window.location.href = `/${document.body.dataset.site}/empresas#contact-section`}
        />
      </div>
      <div className="il-ia-wellness-section-right">
        
      </div>
    </div>
  )
}

export default IAWellnessSection