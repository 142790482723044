import React from 'react'
import { useTranslation, Trans } from 'react-i18next';

const AboutUsSection = () => {
    
    const { t } = useTranslation('footer')

    return (
        <div className="about-us-section">
            <ul>
                <li>
                    <a href={`/${document.body.dataset.site}/sobre-nosotros`}>{t('aboutUsSectionText1')}</a>
                </li>
                <li>
                    <a href={`/${document.body.dataset.site}/faq`}>{t('aboutUsSectionText2')}</a>
                </li>
                <li>
                    <a href={`/${document.body.dataset.site}/terminos`}>{t('aboutUsSectionText3')}</a>
                </li>
                <li>
                    <a href={`/${document.body.dataset.site}/terminos`}>{t('aboutUsSectionText4')}</a>
                </li>
                <li>
                    <a href={`/${document.body.dataset.site}/precios`}>{t('aboutUsSectionText5')}</a>
                </li>
            </ul>
        </div>
    )
}

export default AboutUsSection
