import React from "react";


class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { error: null, eventID: null };
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error });
    /*Sentry.withScope(scope => {
      scope.setExtras(errorInfo);
      const eventID = Sentry.captureException(error);
      this.setState({ eventId: eventID });
    });*/
  }

  componentDidMount() {
    const el = document.querySelector("#therapeutic-exercises__container");
    if (el) {
      el.style.backgroundColor = "#ffffff";
    }
  }

  handleReportClick = e => {
    e.preventDefault();
    /*Sentry.showReportDialog({ eventId: this.state.eventId });*/
  };

  render() {
    const site = document.body.dataset.site;
    if (this.state.error) {
      //Render fallback UI
      return(
        <div className="error-page">
          <h1>{ site == "en" ? "Ouch!" : "¡Oops!" }</h1>
          <div className="error-page__message-container">
            <h3>
              { site == "en" ? "We're sorry, it seems there was an error" : "Lo sentimos, parece que hubo un error"}
            </h3>
          </div>

          <div className="error-page__useful-links">
            <div className="error-page__useful-links-button-container">
              <a href="/" className="error-page__useful-links-button">
                {site == "en" ? "Reload" : "Volver a cargar"}
              </a>
            </div>
            <div className="error-page__useful-links-list">
              <ul>
                <li><a href={site == "en" ? "mailto:hello@mindwell.ai" : "https://wa.me/56930088061"} target="_blank">
                {site == "en" ? "Contact support" : "Contactar soporte"}</a></li>
              </ul>
            </div>
          </div>
        </div>
      );
    } else {
      //When there's not an error, render the children untouched
      return this.props.children;

    }
  }
}

export default ErrorBoundary;