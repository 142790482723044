import React from 'react'
import { useTranslation, Trans } from 'react-i18next';

const ReserveAnHourSection = () => {
    
    const domain = document.body.dataset.domain
    const { t } = useTranslation('footer')
    return (
        <div className="reserve-an-hour-section">
            <ul>
                <li>
                    <a href={`/${document.body.dataset.site}/psicologos`}>{t('reserveAnHourSectionText1')}</a>
                </li>
                <li>
                    <a href={`/${document.body.dataset.site}/users/sign_up`}>{t('reserveAnHourSectionText2')} {domain == "mindwell" ? "Mindwell.ai" : "Terapi"}</a>
                </li>
                <li>
                    <a href={`/${document.body.dataset.site}/users/sign_in`}>{t('reserveAnHourSectionText3')} {domain == "mindwell" ? "Mindwell.ai" : "Terapi"}</a>
                </li>
                <li>
                    <a href={`mailto:contacto@terapi-app.com`}>{t('reserveAnHourSectionText4')}</a>
                </li>
            </ul>

        </div>
    )
}

export default ReserveAnHourSection
