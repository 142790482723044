import React from 'react'
import Button from '../../../../designSystem/Buttons/Index'
import { useTranslation, Trans } from 'react-i18next';

const SupportProgramSection = () => {

    const { t } = useTranslation('landings')
    
    return (
        <div className='tl-support-program-section' data-aos="fade-up">
            <div className="tl-support-program-left-right-container">
                <div className="tl-support-program-section-left">

                </div>
                <div className="tl-support-program-section-right">
                    <h1>{t('supportProgramSectionText1')}</h1>
                    <div className="tl-advantages-container">
                        <div className="tl-advantage-block">
                            <h3>{t('supportProgramSectionText2')}</h3>
                            <ul>
                                <li>{t('supportProgramSectionText3')}</li>
                                <li>{t('supportProgramSectionText4')}</li>
                                <li>{t('supportProgramSectionText5')}</li>
                            </ul>
                        </div>
                        <div className="tl-advantage-block">
                            <h3>{t('supportProgramSectionText6')}</h3>
                            <ul>
                                <li>{t('supportProgramSectionText7')}</li>
                                <li>{t('supportProgramSectionText8')}</li>
                                <li>{t('supportProgramSectionText9')}</li>
                            </ul>
                        </div>
                        <div className="tl-advantage-block">
                            <h3>{t('supportProgramSectionText10')}</h3>
                            <ul>
                                <li>{t('supportProgramSectionText11')}</li>
                                <li>{t('supportProgramSectionText12')}</li>
                                <li>{t('supportProgramSectionText13')}</li>
                            </ul>
                        </div>
                        <div className="tl-advantage-block">
                            <h3>{t('supportProgramSectionText14')}</h3>
                            <ul>
                                <li>{t('supportProgramSectionText15')}</li>
                            </ul>
                        </div>
                        <div className="tl-advantage-block">
                            <h3>{t('supportProgramSectionText16')}</h3>
                            <ul>
                                <li>{t('supportProgramSectionText17')}</li>
                                <li>{t('supportProgramSectionText18')}</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default SupportProgramSection


