import React, { useState, useEffect } from 'react';
import Button from '../Buttons/Index';

const MobileMenu = ({navbarOptions, mobileButtons, notificationEnabled = false}) => {
    const [menuOpen, setMenuOpen] = useState(false);

    useEffect(() => {
        if (navbarOptions) {
            navbarOptions.forEach((link) => {
                if (link.eventData) {
                    window.trackLinks(`#${link.id}Mobile`, link.eventData.event, { data: { label: link.name, link: link.url, target: "mobile", resource: link.eventData.resource, resource_type: link.eventData.resource_type }});
                }
            });
        }

        if (mobileButtons) {
            mobileButtons.forEach((link) => {
                if (link.eventData) {
                    window.trackLinks(`#${link.id}Mobile`, link.eventData.event, { data: { label: link.name, link: link.url, target: "mobile", resource: link.eventData.resource, resource_type: link.eventData.resource_type }});
                }
            });
        }
    }, []);

    const onMenuClick = () =>{
        let body = document.getElementsByTagName('body')[0]
        if (!menuOpen) {
            body.classList.add("noscroll")
        } else {
            body.classList.remove("noscroll")
        }
        setMenuOpen(!menuOpen);
    }

    return (
        <div className='mobile-menu-container'>
            <div className="hamburguer-container">
                {notificationEnabled && <i className="fas fa-circle notification-point"></i>}

                <i className={`fas ${menuOpen ? "fa-times" : "fa-bars"} fa-bars menu-icon`} onClick={onMenuClick}></i>
            </div>
            
            { 
            <div className={`mobile-menu ${menuOpen ? "" : "d-none"}`}>
                <ul>
                    {navbarOptions && navbarOptions.map((option, index) => (
                        <li key={index} onClick={onMenuClick}>
                            <a href={option.url} id={option.id ? `${option.id}Mobile` : `${index}-navbar-mobile`}>{option.name}</a>
                        </li>
                    ))}
                </ul>
                <div className="buttons-container">
                    {mobileButtons && mobileButtons.map( (mobileButton,index) =>(
                        <Button
                            key={index}
                            id={mobileButton.id ? `${mobileButton.id}Mobile` : `${index}-navbar-mobile`}
                            type={mobileButton.type}
                            color={mobileButton.color}
                            text={mobileButton.text}
                            onClickHandler={() => {mobileButton.onClickHandler(); onMenuClick()}}
                        />
                    ))}
                </div> 
            </div>}
        </div>
    )
}

export default MobileMenu
