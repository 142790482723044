import React,{useEffect} from 'react'

const MeterIndicator = () => {

    useEffect(() => {
        var firstBlock = document.getElementById("first-block")
        var div1rect = firstBlock.getBoundingClientRect();
        var distanceToTop = window.pageYOffset + div1rect.top;
        var distanceWithoutNavbar = distanceToTop - 150
        var firstBlockHeight = div1rect.height
        console.log(div1rect)
        

        const onScroll = function() {
          /* var elementTarget = document.getElementById("il-meter-indicator");
          console.log(window.scrollY,distanceToTop)
          if ((window.scrollY + 60 > distanceToTop) && ((window.scrollY - size.height*factor) * 0.05) < 100) {
              elementTarget.style.height = (window.scrollY - size.height*factor) * 0.05 + "%"
              if(((window.scrollY - size.height*factor) * 0.05) > 98){
                elementTarget.style.height = "100%"
              }
              console.log(window.scrollY,elementTarget.style.height,factor)
          }  */

            var elementTarget = document.getElementById("il-meter-indicator");
            var winScroll = document.body.scrollTop  || document.documentElement.scrollTop  ;
            var scrolled = (winScroll-distanceToTop+150) / firstBlockHeight * 100;

            if ((window.scrollY > distanceWithoutNavbar) && scrolled < 100) {
                elementTarget.style.height = scrolled + "%"
                console.log(window.scrollY,distanceToTop,winScroll-distanceToTop, scrolled)
            } 
        } 
        window.addEventListener("scroll", onScroll);
        
        return () => window.removeEventListener('scroll', onScroll);
    }, [])
  return (
    <>
        <span className='il-meter-bar'></span>
        <span className="il-meter-indicator" id='il-meter-indicator'></span>
    </>
  )
}

export default MeterIndicator