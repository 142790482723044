import React from 'react'
import googlePlayLogo from 'images/google-play-badge-en.png'
import appleStoreLogo from 'images/applestore-logo.png'

const AppStoreLogos = () => {

  const domain = document.body.dataset.domain
  
  return (
    <div className="lnd-stores-logos-container">
        <a href={domain == "mindwell" ? "https://play.google.com/store/apps/details?id=com.psycaid.mindwell" : "https://play.google.com/store/apps/details?id=com.psycaid.terapi&hl=es_CL&gl=US&pli=1"}>
            <img src={googlePlayLogo} alt="" className="lnd-store-logo" />
        </a>
        <a href={domain == "mindwell" ? "https://apps.apple.com/cl/app/mindwell-coach-de-autocuidado/id6448058108" : "https://apps.apple.com/us/app/terapi-psic%C3%B3logos-online/id1547374655"}>
            <img src={appleStoreLogo} alt="" className="lnd-store-logo" />
        </a>
    </div>
  )
}

export default AppStoreLogos