import React from 'react';
import PropTypes from 'prop-types';

const Button = ({id, text, type ,color, disabled, href, onClickHandler}) => {
  const onClick = (e) => {
    if (onClickHandler) {
      e.preventDefault();
      onClickHandler(e);
    }
  }

  const render = () => {
    if (href) {
      return (
        <a id={id} href={href} className={`${type}-${color}`}>
          <span className='button-span' dangerouslySetInnerHTML={{__html: text}}></span>
        </a>
      )
    } else {
      return (
        <a id={id} href="#" onClick={(e) => onClick(e)} className={`${type}-${color}`}>
          <span className='button-span' dangerouslySetInnerHTML={{__html: text}}></span>
        </a>
      )
    }
  }

  return render();
}

Button.propTypes = {
  id: PropTypes.string,
  text: PropTypes.string.isRequired,
  type: PropTypes.oneOf(["fill", "outline"]).isRequired,
  color: PropTypes.oneOf(["primary", "accent", "gray","gold","disabled", "not_started", "started", "cancelled", "completed", "whatsapp", "btn-gradient"]).isRequired,
  disabled: PropTypes.bool,
  href: PropTypes.string,
  onClickHandler: PropTypes.func,
}

export default Button;