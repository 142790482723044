import React from 'react'
import Button from '../../../../designSystem/Buttons/Index'
import { useTranslation, Trans } from 'react-i18next';

const KnowMoreSection = () => {

  const { t } = useTranslation('landings')

  return (
    <div className='tl-know-more-section' data-aos="fade-up">
        <h1>{t('knowMoreSectionText1')}</h1>
        <p>{t('knowMoreSectionText2')}</p>
        <div className="buttons-container">
            <Button 
                type='fill'
                color='accent'
                text={t('knowMoreSectionText3')}
                onClickHandler={() => window.location.href = `/${document.body.dataset.site}/empresas#contact-section`}
            />
        </div>
    </div>
  )
}

export default KnowMoreSection