import React from 'react'
import { useTranslation, Trans } from 'react-i18next';

const OnlineTherapySection = () => {

    const { t } = useTranslation('footer')
    
    return (
        <div className="online-therapy-section">
            <ul>
                <li>
                    <a href={`/${document.body.dataset.site}/empresas`}>{t('onlineTherapySectionText1')}</a>
                </li>
                <li>
                    <a href={`/${document.body.dataset.site}/psychologists/sign_up`}>{t('onlineTherapySectionText2')}</a>
                </li>
            </ul>
        </div>
    )
}

export default OnlineTherapySection
