import React from 'react'
import MeterIndicator from '../MeterIndicator'

const NomWorkflowSection = () => {
  return (
    <div className='nom-workflow-section'>
        <h1 className="nom-workflow-title">
          Cómo funciona
        </h1>
        <div className="nom-workflow-blocks-container" id='first-block'>
            <MeterIndicator/>
            <div className="nom-workflow-block first" >
                <div className="nom-workflow-block-left">

                </div>
                <div className="nom-workflow-block-right">
                    <h2>Aplica el cuestionario</h2>
                    <p>Aplica el cuestionario siguiendo la guía de Referencia I y II/III. Evalua a todos tus colaboradores de manera digital, a través de una plataforma simple de usar y segura. Ahorra tiempos a tu departamente de recursos humanos y tus colaboradores con una plataforma que facilita el proceso de realización de los cuestionarios de la NOM-035.</p>
                </div>
            </div>

            <div className="nom-workflow-block second">
                <div className="nom-workflow-block-left">
                <h2>Obtén los resultados de manera automática</h2>
                <p>Revisa los resultados del cuestionario a través de nuestra plataforma web, filtra por departamento y datos sociodemográficos. No gastes tiempo recopilando respuestas, todo tus resultados en un sólo lugar y de manera automática e inmediata.</p>
                </div>
                <div className="nom-workflow-block-right">
                
                </div>
            </div>

            <div className="nom-workflow-block third">
                <div className="nom-workflow-block-left">

                </div>
                <div className="nom-workflow-block-right">
                    <h2>Cumple tus requerimientos legales con todas las evidencias documentales necesarias</h2>
                <p>¡No te arriesgues a multas!, Genera automáticamente las evidencias documentales para cumplir con los requerimientos legales en caso de una fiscalización, encuentra todas tus evidencias de manera permanente en tu sistema de administración.</p>
                </div>
            </div>

            <div className="nom-workflow-block fourth" >
                <div className="nom-workflow-block-left">
                <h2>Entrega soporte a los colaboradores que lo necesiten</h2>

                <p>Realiza el seguimiento y evaluación de tus colaboradores afectados por eventos traumáticos con uno de nuestros psicólogos profesionales. Entrega ayuda a todo colaborador que lo necesite con nuestra extensa red de profesionales de la salud.</p>
                </div>
                <div className="nom-workflow-block-right">
                
                </div>
            </div>
            <div className="nom-workflow-block fifth">
                <div className="nom-workflow-block-left">

                </div>
                <div className="nom-workflow-block-right">
                    <h2>Crea tu política de prevención y un programa de mitigación y control</h2>
                <p>Te ayudamos a construir una política de prevención de riesgos psicosociales y un programa de intervenciones de mitigación y control de nivel primario, secundario y terciario, para comenzar a cuidar de manera efectiva el bienestar mental y emocional de tus trabajadores. </p>
                </div>
            </div>
        </div>
    </div>
  )
}

export default NomWorkflowSection