import { locale } from "moment";

const getLocale = () => {
  const site = document.body.dataset.site;
  const localeMapper = {
    es: "es",
    cl: "es",
    mx: "es",
    en: "en"
  }

  return localeMapper[site];
}

export default getLocale;