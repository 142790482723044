import React from 'react'
import Button from '../../../../designSystem/Buttons/Index'

const NomHelpSection = () => {
  return (
    <div className='nom-help-section-container'>
      <div className="nom-help-section-left">
        <h1>Cumple con la NOM-035 con nuestra ayuda</h1>
        <p>Aplica los cuestionarios de la <b>NOM-035</b> de acuerdo a tus necesidades, obtén tus resultados de manera online y en tiempo real. Genera automáticamente las evidencias documentales para cumplir con la <b>NOM-035</b>. Crea una <b>política de prevención</b> e implementa medidas de mitigación y control con ayuda de nuestro equipo de psicólogos profesionales.</p>
        <Button
          type='fill'
          color='accent'
          text='Aplica el cuestionario de manera <b style="color: #ffde59;">Gratuita</b>'
          onClickHandler={() => window.location.href = `/${document.body.dataset.site}/empresas#contact-section`}
        />
      </div>
      <div className="nom-help-section-right">
        
      </div>
    </div>
  )
}

export default NomHelpSection