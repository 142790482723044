import React from 'react'
import MeterIndicator from '../MeterIndicator'
import { useTranslation, Trans } from 'react-i18next';

const IAWorkflowSection = () => {

  const { t } = useTranslation('landings')

  return (
    <div className='il-ia-workflow-section'>
        <h1 className="il-ia-workflow-title">
          {t('iAWorkflowSectionText1')}
        </h1>
        <div className="il-ia-workflow-blocks-container" id='first-block'>
          <MeterIndicator/>
          <div className="il-ia-workflow-block first" >
            <div className="il-ia-workflow-block-left">

            </div>
            <div className="il-ia-workflow-block-right">
                <h2>{t('iAWorkflowSectionText2')}</h2>
                <p>{t('iAWorkflowSectionText3')}</p>
            </div>
          </div>

          <div className="il-ia-workflow-block second">
            <div className="il-ia-workflow-block-left">
              <h2>{t('iAWorkflowSectionText4')}</h2>
              <p>{t('iAWorkflowSectionText5')}</p>
            </div>
            <div className="il-ia-workflow-block-right">
              
            </div>
          </div>

          <div className="il-ia-workflow-block third">
            <div className="il-ia-workflow-block-left">

            </div>
            <div className="il-ia-workflow-block-right">
                <h2>{t('iAWorkflowSectionText6')}</h2>
              <p>{t('iAWorkflowSectionText7')}</p>
            </div>
          </div>

          <div className="il-ia-workflow-block fourth" >
            <div className="il-ia-workflow-block-left">
              <h2>{t('iAWorkflowSectionText8')}</h2>

              <p>{t('iAWorkflowSectionText9')}</p>
            </div>
            <div className="il-ia-workflow-block-right">
              
            </div>
          </div>

        </div>
    </div>
  )
}

export default IAWorkflowSection