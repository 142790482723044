import React from 'react'
import chileFlag from 'images/footer/chile.png'
import mexicoFlag from 'images/footer/mexico.png'
import Hr from '../Hr/Index'
import { useTranslation } from 'react-i18next';
import AppStoreLogos from './AppStoreLogos';

const LogoSection = ({ desktopLogo, b2b, logoClass, contactEmail }) => {

    const { t } = useTranslation('footer')
    const domain = document.body.dataset.domain
    
    return (
        <div className="logo-section">
            <a href="">
                <img src={desktopLogo} alt="" className={logoClass} />
            </a>

            <p>{t('logoSectionText1')}</p>
            <AppStoreLogos />
            <Hr />
            {domain == "mindwell" &&
                <div className="lnd-social-network-logos-container">
                    <a href="https://www.instagram.com/mindwellai">
                        <i className="fab fa-instagram lnd-logo"></i>
                    </a>
                    <a href="https://www.twitter.com/mindwellai">
                        <i className="fab fa-twitter lnd-logo"></i>
                    </a>
                    <a href="https://cl.linkedin.com/company/mindwellai">
                        <i className="fab fa-linkedin lnd-logo"></i>
                    </a>
                </div>
            }
            {domain != "mindwell" &&
                <div className="lnd-social-network-logos-container">
                    <a href="https://www.facebook.com/terapiapp/">
                        <i className="fab fa-facebook lnd-logo"></i>
                    </a>
                    <a href="https://www.instagram.com/terapi_app/?hl=es">
                        <i className="fab fa-instagram lnd-logo"></i>
                    </a>
                    <a href="https://cl.linkedin.com/company/terapiapp">
                        <i className="fab fa-linkedin lnd-logo"></i>
                    </a>
                </div>
            }
            {b2b ?
                (<div className="countries-directions">
                    <div className='country-direction'>
                        <img src={chileFlag} alt="" />
                        <p>Nueva Los Leones 0233, Providencia, Santiago, 7500000, Chile</p>
                    </div>
                    <div className='country-direction'>
                        <img src={mexicoFlag} alt="" />
                        <p>Prolongación Paseo De La Reforma 1015, Ciudad de México, 05348, México</p>
                    </div>
                </div>)
                :
                <p>{contactEmail}</p>
            }
        </div>
    )
}

export default LogoSection
